import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Types
import { IAppProvider } from "../types"

// HOCs
import { withApp } from "../hocs/"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { duration, ease } from "../styles/animations"
import { mq } from "../styles/utils/mq"

// Components
import { Col } from "./layout/Grid"
import SocialMediaIcons from "./SocialMediaIcons"

type ITheme = "light" | "dark"

interface IProps {
  app: IAppProvider
  className?: string
  theme?: ITheme
}

const SocialBlock = ({ app, className, theme = "light" }: IProps) => {
  const { socialBlock } = useStaticQuery(graphql`
    query {
      socialBlock: prismicSocialBlock {
        data {
          title
          links {
            url
            type
          }
        }
      }
    }
  `)

  const data = socialBlock.data

  if (!data) {
    return null
  }

  return (
    <Wrapper
      isExpanded={app.isSiteHeaderExpanded}
      className={className}
      theme={theme}
    >
      <Decoration isExpanded={app.isSiteHeaderExpanded} />
      <Content isExpanded={app.isSiteHeaderExpanded}>
        {data.title && (
          <Col m={8}>
            <Heading>{data.title}</Heading>
          </Col>
        )}

        {data.links && data.links.length && (
          <Col m={8}>
            <SocialMediaWrapper theme={theme}>
              {/* Inverse the theme of the icons */}
              <SocialMediaIcons
                links={data.links}
                theme={theme === "dark" ? "light" : "dark"}
              />
            </SocialMediaWrapper>
          </Col>
        )}
      </Content>
    </Wrapper>
  )
}

// Main styles
const Wrapper = styled.div<{ theme: ITheme; isExpanded: boolean }>`
  overflow: hidden;
  margin-top: auto;
  visibility: ${({ isExpanded }) => !isExpanded && "hidden"};
  transition: visibility ${duration.medium}ms ${ease.cubic};
  transition-delay: ${(props: { isExpanded: boolean }) =>
    !props.isExpanded && "400ms"};
`

const Decoration = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  height: 1px;
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  background-color: ${colors.black};
  transition: opacity ${duration.medium}ms ${ease.cubic};
  transition-delay: ${(props: { isExpanded: boolean }) =>
    !props.isExpanded && "400ms"};
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => (theme === "dark" ? colors.white : colors.black)};
  text-align: center;
  padding-top: 8px;
  transition: transform ${duration.medium}ms ${ease.cubic};
  ${(props: { isExpanded: boolean }) =>
    props.isExpanded
      ? `
        transform: translateY(0);
        transition-delay: 150ms;
    `
      : `
        transform: translateY(-100%);
    `}

  ${mq.from.S`
    padding-top: 16px;
  `}

  ${mq.from.M`
    text-align: left;
  `}
`

const Heading = styled.h3`
  ${textStyles.headingM};

  ${mq.from.M`
    margin-bottom: 0;
  `}
`

const SocialMediaWrapper = styled.div`
  a:hover svg {
    fill: ${({ theme }) => (theme === "dark" ? colors.black : colors.white)};
  }
`

export default withApp(SocialBlock)
