import React from "react"
import { graphql } from "gatsby"

// Components
import SocialResponsibilityPage from "../pageViews/socialResponsibilityPageView"

const SocialResponsibilityPageContainer = ({ data }: any) => {
  if (!data.socialResponsibility) {
    return null
  }

  const rawData = data.socialResponsibility.data

  const formattedData = {
    metaTitle: rawData.meta_title,
    metaDescription: rawData.meta_description,
    pageTitle: rawData.page_title.text,
    introTitle: rawData.intro_title.text,
    introCopy: {
      html: rawData.intro_text.html,
    },
  }

  const slices = rawData.body.map((slice: any) => {
    if (slice.type === "title__text") {
      return {
        type: slice.type,
        title: slice.primary.text_title.text,
        text: slice.primary.text_text.html,
      }
    } else if (slice.type === "social_cases") {
      const items = slice.items.map((item: any) => {
        return {
          title: item.case_client,
          description: item.case_text.text,
          image: {
            url: item.case_image.url,
            alt: item.case_image.alt,
          },
        }
      })
      return {
        type: slice.type,
        items,
      }
    } else if (slice.type === "title__media__text") {
      return {
        type: slice.type,
        title: slice.primary.media_title.text,
        text: slice.primary.media_text.html,
        image: {
          url: slice.primary.media_image.url,
          alt: slice.primary.media_image.alt,
        },
        link: {
          label: slice.primary.media_link_label,
          url: slice.primary.media_link.url,
        },
      }
    } else {
      return null
    }
  })

  return <SocialResponsibilityPage data={formattedData} slices={slices} />
}

export const query = graphql`
  query GetSocialResponsibility {
    socialResponsibility: prismicSocialResponsibilityPage {
      data {
        meta_title
        meta_description
        page_title {
          text
        }
        intro_title {
          text
        }
        intro_text {
          html
        }
        body {
          ... on PrismicSocialResponsibilityPageBodyTitleText {
            type: slice_type
            primary {
              text_title {
                text
              }
              text_text {
                html
              }
            }
          }
          ... on PrismicSocialResponsibilityPageBodyTitleMediaText {
            type: slice_type
            primary {
              media_title {
                text
              }
              media_text {
                html
              }
              media_image {
                url
                alt
              }
              media_link_label
              media_link {
                url
              }
            }
          }
          ... on PrismicSocialResponsibilityPageBodySocialCases {
            type: slice_type
            items {
              case_client
              case_sector
              case_text {
                text
              }
              case_image {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`

export default SocialResponsibilityPageContainer
