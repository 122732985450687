import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

// Utils
import PageContext from "../utils/context/PageContext"

// Styling
import colors from "../styles/colors"

// Components
import SEO from "../components/layout/SEO"
import PageHeader from "../components/layout/PageHeader"
import TextBlock from "../components/TextBlock"
import TextMedia from "../components/TextMedia"
import ImageGrid from "../components/ImageGrid"
import SocialBlock from "../components/SocialBlock"

// Types
import { IPage } from "../types/"

interface ISliceTitleText {
  type: "title__text"
  title: string
  text: string
}

interface ICase {
  title: string
  description: string
  image: {
    url: string
    alt?: string
  }
}

interface ISliceCases {
  type: "social_cases"
  items: ICase[]
}

interface ISliceTitleMediaText {
  type: "title__media__text"
  title: string
  text: string
  image: {
    url: string
    alt?: string
  }
  link: {
    label: string
    url: string
  }
}

type ISlice = ISliceTitleText | ISliceCases | ISliceTitleMediaText

interface IProps {
  data: IPage
  slices: ISlice[]
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.white,
  },
}

const SocialResponsibilityPage = ({ data, slices }: IProps) => {
  if (!data) {
    return null
  }

  const page = useContext(PageContext)

  useEffect(() => {
    page.updateColor(colors.black)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Container>
      <SEO title={data.metaTitle} description={data.metaDescription} />
      <PageHeader
        pageTitle={data.pageTitle}
        introTitle={data.introTitle}
        introCopy={data.introCopy}
      />
      {slices &&
        slices.length &&
        slices.map((slice, i) => {
          switch (slice.type) {
            case "title__text":
              return (
                <Section>
                  <TextBlock
                    key={`slice-${i}`}
                    title={slice.title}
                    text={{ html: slice.text }}
                    alignment="left"
                  />
                </Section>
              )
            case "title__media__text":
              return (
                <Section>
                  <TextMedia
                    key={`slice-${i}`}
                    title={[slice.title]}
                    copy={{ html: slice.text }}
                    linkTitle={slice.link.label}
                    link={{
                      url: slice.link.url,
                      target: "_blank",
                    }}
                    media={{
                      kind: "image",
                      url: slice.image.url,
                      alt: slice.image.alt ? slice.image.alt : "",
                    }}
                    mediaAlignment="left"
                    mediaHasPadding="off"
                    hasSpacing="off"
                  />
                </Section>
              )
            case "social_cases":
              return (
                <Section>
                  <ImageGrid
                    key={`slice-${i}`}
                    items={slice.items}
                    featuredItemPosition="first"
                  />
                </Section>
              )
            default:
              return null
          }
        })}
      <SocialBlock />
    </Container>
  )
}

const Container = styled.div`
  padding-top: 48px;
  color: ${colors.white};
`

const Section = styled.section`
  margin-bottom: calc(var(--base-column-size) * 2);
`

export default SocialResponsibilityPage
